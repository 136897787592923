// src/services/tokenService.js
import store from '@/store';
import axios from 'axios';
import { computed } from 'vue'

import { jwtDecode } from 'jwt-decode';

let isRefreshing = false;
let failedQueue = [];
const API_KEY = process.env.VUE_APP_API_KEY;


// Check if the token is expired
const isTokenExpired = (token) => {
    if (!token) return true;
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000; // Compare with current time
};
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL || '/api',
    // baseURL: process.env.NODE_ENV === 'production'? process.env.VUE_APP_API_URL : '/api/',
});

// Intercept requests to check token expiration before each request
axiosInstance.interceptors.request.use(
    async (config) => {

        //chang the request base url here
        if (process.env.NODE_ENV !== 'production')
            config.url = config.url.replace('/api', '');
        // console.log("config", config.baseURL, ' ', config.url);

        const state = store.state; // Get the current Vuex state
        const accessToken = state.user?.accessToken;

        // If the token exists and is expired, refresh it before proceeding
        if (accessToken && isTokenExpired(accessToken)) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const newToken = await store.dispatch('refreshToken'); // Refresh token action

                    config.headers['Authorization'] = `Bearer ${newToken}`;
                    config.headers['apiKey'] = API_KEY;
                    isRefreshing = false;
                    processQueue(null, newToken);
                } catch (err) {
                    processQueue(err, null);
                    throw err;
                }
            } else {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        config.headers['Authorization'] = 'Bearer ' + token;
                        config.headers['apiKey'] = API_KEY;
                        return config;
                    })
                    .catch(err => Promise.reject(err));
            }
        } else if (accessToken) {
            // If token is not expired, proceed with the original access token

            config.headers['Authorization'] = `Bearer ${accessToken}`;
            config.headers['apiKey'] = API_KEY;

        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response, // This handles successful responses
    async (error) => {
        const originalRequest = error.config;
        const isLoggedIn = computed(() => store.getters.loggedIn);

        // If we get a 401 Unauthorized and it's not a retry request, try to refresh the token
        if (isLoggedIn.value)
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const refreshToken = store.state.user?.refreshToken;

                    // Make sure refresh token exists before trying
                    if (!refreshToken) {
                        throw new Error('Refresh token is missing');
                    }

                    // Try to refresh the token
                    const response = await store.dispatch('refreshToken');

                    if (response) {
                        originalRequest.headers['Authorization'] = `Bearer ${response}`;
                        originalRequest.headers['apiKey'] = API_KEY;
                        return axiosInstance(originalRequest); // Retry the original request
                    } else { 
                        //When we open the account from another device the refresh token will be not vaild and we have to redirect to Login
                        window.location.href = "/auth/login";
                        throw new Error('Failed to refresh token'); 
                    }
                } catch (err) {
                    store.dispatch('logout'); // Log the user out if refresh fails
                    return Promise.reject(err);
                }
            }

        return Promise.reject(error); // Pass any other errors down the chain
    }
);



export default axiosInstance;
