<template>
  <router-view />
</template>

<script>
import { watch, onMounted, onUnmounted, computed } from 'vue'//ref,
import { useStore } from 'vuex'
//import axios from 'axios'
// import axiosInstance from '@/services/tokenService';
import '@/plugins/styles'

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const isLoggedIn = computed(() => store.getters.loggedIn);
    const userDataString = computed(() => store.getters['user']);
    // store .dispatch('setting/setSetting')
    const sidebarType = computed(() => store.getters['setting/sidebar_type'])


    const resizePlugin = () => {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('on-resize')
            store.dispatch('setting/sidebar_type', [...sidebarType.value, 'sidebar-mini'])
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            sidebarResponsive.classList.remove('on-resize')
            store.dispatch('setting/sidebar_type',
              sidebarType.value.filter((item) => item !== 'sidebar-mini')
            )
          }
        }
      }
    }

    const automaticLogin = () => {
      console.log("userDataString.value", userDataString.value);
      console.log("isLoggedIn?", isLoggedIn.value);


      if (isLoggedIn.value) {
        // const userData = JSON.parse(userDataString.value)
        // store.commit('SET_USER_DATA', userData);
        store.dispatch('getSelectDropdownsOptions')

        // interceptorsResponse();
      } else {
        store.dispatch('logout')
        // alert("dispatch loged-out");
      }
      // return user String.value.userName;
    }


    // const interceptorsResponse = () => {
    //   return axios.interceptors.response.use(
    //     response => response,
    //     error => {
    //       if (error.response.status === 401) {
    //         store.dispatch('refreshToken') // store.dispatch('logout')  
    //       }
    //       return Promise.reject(error) // reject the Promise, with the error as the reason
    //     }
    //   )
    // }

    watch(userDataString, (newUserString) => {

      if (newUserString) {
        console.log("newUserString updated", newUserString);
        store.dispatch('getSelectDropdownsOptions')
      }
    })

    onMounted(async () => {
      console.log("onMounted called in App.vue");
      if (location.pathname !== "/auth/login")
        automaticLogin();


      window.addEventListener('resize', resizePlugin)
      setTimeout(() => {
        resizePlugin()
      }, 200);

    })

    onUnmounted(() => {
      window.removeEventListener('resize', resizePlugin)
    })

  }
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';

iframe#webpack-dev-server-client-overlay {
  display: none;
}
</style>
