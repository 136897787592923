import { createRouter, createWebHistory } from 'vue-router'


// Default routes
const defaultChildRoutes = (prefix) => [

  // Custom Pages
  {
    path: '/guests',
    name: prefix + '.guests',
    meta: { requiresAuth: true, name: 'Guests', isBanner: true },
    component: () => import('@/views/reception-interfaces/GuestsPage.vue')
  },
  {
    path: '/confirm-email',
    name: '.confirm-email',
    meta: { requiresAuth: true, name: 'ConfirmEmail', isBanner: false },
    component: () => import('@/views/reception-interfaces/ConfirmEmail.vue')
  },

  // Users Pages 
  {
    path: '/user-profile',
    name: prefix + '.user-profile',
    meta: { requiresAuth: true, name: 'User Add', isBanner: true },
    component: () => import('@/views/reception-interfaces/ProfilePage.vue')
  },
  {
    path: '/user-add',
    name: prefix + '.user-add',
    meta: { requiresAuth: true, name: 'User Add', isBanner: true },
    component: () => import('@/views/reception-interfaces/GuestFormPage.vue')
  },
  {
    path: '/user-edit',
    name: prefix + '.user-edit',
    meta: { requiresAuth: true, name: 'User Edit', isBanner: true },
    component: () => import('@/views/reception-interfaces/GuestFormPage.vue')
  },
  {
    path: '/check-voucher', name: 'Check Voucher',
    meta: { requiresAuth: true, isBanner: true },
    component: () => import('@/views/reception-interfaces/VoucherPage.vue')
  },


]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { requiresAuth: true, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { requiresAuth: true, name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { requiresAuth: true, name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [

  {
    path: '/auth',
    name: 'Auth',
    meta: { auth: false },
    component: () => import('../layouts/BlankLayout.vue'),
    children: [
      { path: '', redirect: '/login', },

      {
        path: 'login',
        meta: { auth: false },
        component: () => import('@/views/auth/SignIn.vue')
      },

      {
        path: 'reset-password',
        meta: { auth: false },
        component: () => import('@/views/auth/ResetPassword.vue')
      }

    ]
  },


  // Default Pages
  {
    path: '/',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        redirect: '/guests',
      },
      ...defaultChildRoutes('default')
    ]
  },



  // Errors Pages
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/BlankLayout.vue'),
    children: errorRoutes('errors')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/errors/Error404Page.vue')
  }
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/auth/login')
  }
  next()
})

export default router
